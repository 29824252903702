@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Quicksand:wght@300;700&display=swap);
* {
	margin: 0;
	padding: 0;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.spinnerContainer {
	min-height: 70vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.menu-links {
	font-family: Quicksand, sans-serif;
	color: #292929;
	text-decoration: none;
	text-transform: uppercase;
}

.user-account {
	color: #292929;
	width: 100%;
	text-align: start;
	text-decoration: none;
}

.timer {
	position: absolute;
	top: 20px;
	right: 45px;
}

@media screen and (max-width: 1200px) {
	.timer {
		top: 19px;
		right: 45px;
	}
}

@media screen and (max-width: 600px) {
	.timer {
		top: 15px;
		right: 45px;
	}
}

.navbarLogo img {
	width: auto;
	height: 100%;
}

.gridContainer {
  position: relative;
	min-height: auto;
	padding: 0 0 2rem;
}

.linkGridHome {
	text-decoration: none;
	color: #000000;
}

.productImgCard {
	display: block;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	object-fit: cover;
	border-radius: 5px;
	height: 80%;
	width: 100%;
}

.sliderContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.sliderItemDetail {
  width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}

.colorsArrow {
  opacity: 30%;
}

.priceContainer {
	position: relative;
	display: flex;
}

.priceContainer__priceDiscount {
	position: absolute;
	bottom: 32px;
	right: 4px;
	background-color: #42ab49;
	padding: 3px 4px;
	border-radius: 5px;
}

.homeContainer {
	display: flex;
	flex-direction: column;
	align-content: center;
	padding-right: 20px;
	padding-left: 20px;
	margin-bottom: 2rem;
}

.boxCategories {
	text-align: center;
	padding-top: 25px;
	margin-top: 5px;
}

.imgCategoriesContainer {
	display: flex;
	justify-content: center;
}

.slick-next {
	display: none !important;
}

.slick-prev {
	display: none !important;
}

.imgCategories {
	border-radius: 100%;
	width: 70px;
	height: 70px;
}

.nameCategory {
	font-family: "Quicksand", sans-serif;
	text-transform: lowercase;
}

.nameCategory::first-letter {
	text-transform: uppercase;
}

.imgBannerContainer {
	padding-top: 10px;
	max-height: 400px;
}

.imgBanner {
	max-height: 400px;
	width: 100%;
	border-radius: 5px;
	object-fit: cover;
}

.imgGridContainer {
	position: relative;
	margin-top: 45px;
	height: 100%;
}

.gridContainer {
	min-height: auto;
}

.linkGridHome {
	text-decoration: none;
	color: #000000;
}

.imgGrid {
	border-radius: 5px;
	height: 80%;
}

.nameCard {
	text-transform: capitalize;
}
.sliderCategoriesContainer {
  display: flex;
  margin-top: 2rem;
  align-items: center;
  width: 100%;
}
.sliderCategories {
  width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}

.categoryContainer {
  margin: 0 2rem;
}

.sliderCategoryImgContainer {
  display: flex;
	justify-content: center;
}

.sliderCategoryImg {
	border-radius: 100%;
	width: 70px;
	height: 70px;
}

.sliderCategoryName {
  font-family: "Quicksand", sans-serif;
	text-transform: lowercase;
  text-align: center;
}

.sliderCategoryName::first-letter {
	text-transform: uppercase;
}
.login-logo {
	position: relative;
	right: 50%;
	left: 50%;
	margin: 5% auto 10%;
	width: 120px;
	height: auto;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
}

.create-user-link {
	color: #fafafa;
	text-decoration: none;
}

.swal2-styled.swal2-confirm {
  color: #fafafa;
  background-color: #292929;
  box-shadow: 0 0 0 3px rgb(100 150 200 / 50%);
}

.swal2-title.swalTitle {
  font-size: 1.3rem;
}

#btnForm {
	margin-top: 10px;
	margin-bottom: 20px;
	padding: 5px;
}

.errorMessage {
	color: red;
	font-size: 13px;
	margin-left: 5px;
}

.main {
	background-color: #0f0f0f;
	min-height: 100vh;
}

.form-logo {
	width: 120px;
	height: auto;
	padding-bottom: 30px;
	padding-top: 20px;
}

.css-1f0jaut-MuiButtonBase-root-MuiCheckbox-root {
	color: #fafafa;
}

input {
	border-color: #fafafa;
}

.css-an51-MuiButtonBase-root-MuiButton-root {
	font-size: 1rem;
	font-weight: 700;
	border-radius: 0;
	text-transform: none;
}

.css-1exth5i-MuiButtonBase-root-MuiButton-root {
	text-transform: none;
	margin-top: 0.7rem;
}

.swal2-styled.swal2-confirm.swalBtnOk {
	color: white;
}

.swal2-styled.swal2-confirm.swalBtnOk {
	color: #fafafa;
	box-shadow: 0 0 0 3px rgb(100 150 200 / 50%);
}

.swal2-title.swalTitle {
	font-size: 1.3rem;
}

.itemcountContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #f5f6f7;
	border-radius: 5px;
	max-width: 120px;
	min-width: 105px;
	margin: 0 auto;
}

.sliderColors {
	display: flex;
	flex-wrap: wrap;
}

.sliderColors::-webkit-scrollbar {
	display: none;
}

.paletteColor {
	border: 1px solid rgb(197, 195, 195);
	border-radius: 100%;
	margin: 8px;
}

.typographyItemDetail {
	text-transform: capitalize;
	padding-bottom: 15px;
}

.sizeTextColor {
	padding-top: 10px;
	text-align: left;
	font-family: "Quicksand", sans-serif;
}

.typographyItemDetailDescription {
	text-align: left;
	padding-top: 10px;
	font-family: "Quicksand", sans-serif;
	text-transform: capitalize;
}

.linkBtnSizesAndColor {
	text-decoration: none;
	width: 100%;
}

.sizeGuide {
	padding: 40px 0px 20px 0px;
	color: #000000;
}

.linksizeGuide {
	text-decoration: none;
}

.shippingMethods {
	padding: 20px 0px 40px 0px;
}

.iconItemDetail {
	padding-right: 20px;
	color: #000000;
}

.cardActionsButton {
	display: flex;
	flex-direction: column;
}

.productAdded {
	font-size: 1.1rem;
}

.productAddedIcon {
	font-size: 0.9rem;
}

.itemDetailContainer {
	position: relative;
	height: 100%;
	display: flex;
	justify-content: center;
	max-width: 1538px;
	margin: 0 auto 2rem;
}

.cardContainer {
	position: relative;
	margin-top: 3rem;
	height: 100%;
	min-width: 100vw;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.detailContent {
	margin: 0 1.5rem;
}

.boxBtnsAndImg {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.btnBackItemDetail {
	border: hidden;
	position: absolute;
	border-radius: 100%;
	left: 0.8rem;
	padding: 1rem;
	background-color: transparent;
}

.btnBackItemDetail:hover {
	background-color: #e7ebf0;
  cursor: pointer;
}

.btnBackItemDetailMobile {
	display: none;
}

.boxButtonsBack {
	display: flex;
	flex-direction: column;
}

.btnChangeImgBack {
	border: hidden;
	position: relative;
	padding: 10px;
	background-color: transparent;
}

.btnChangeImgBack:hover {
	background-color: #e7ebf0;
	border-radius: 20%;
  cursor: pointer;
}

.imgCard {
	width: 100%;
	max-width: 500px;
}

.boxButtonFoward {
	display: flex;
	flex-direction: column;
}

.btnChangeImgFoward {
	border: hidden;
	position: relative;
	padding: 10px;
	background-color: transparent;
}

.btnChangeImgFoward:hover {
	background-color: #e7ebf0;
	border-radius: 20%;
  cursor: pointer;
}

.boxBtnHandleChangeImg {
	text-align: center;
	bottom: 0;
	padding-top: 10px;
}

.btnHandleChangeImg {
	border-radius: 100%;
	width: 20px;
	height: 20px;
	margin: 5px;
	border: transparent;
	background-color: #ced4da;
	padding-top: 10px;
}

@media screen and (max-width: 1332px) {
	.detailContainer {
		width: 45%;
	}
}

@media screen and (max-width: 824px) {
	.cardContainer {
		margin-top: 0;
		flex-wrap: wrap;
	}

	.detailContainer {
		width: 100%;
	}

	.btnBackItemDetail {
		display: none;
	}

	.btnBackItemDetailMobile {
		display: inline;
		position: absolute;
		left: 0.5rem;
		top: 1rem;
		border: hidden;
		border-radius: 100%;
		background-color: transparent;
	}

	.btnBackItemDetailMobile:hover {
		background-color: #e7ebf0;
    cursor: pointer;
	}

	.btnChangeImgBack {
		padding: 5px;
	}

	.btnChangeImgFoward {
		padding: 5px;
	}
}

.categorySettings {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0.5rem;
}

.slider {
	overflow-x: scroll;
	overflow-y: hidden;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
	display: none;
}

.filterButtons {
	display: flex;
}

.imgBannerContainer {
	padding-top: 20px;
}

.imgBanner {
	width: 100%;
	height: auto;
	border-radius: 5px;
}

.imgCategoryContainer {
	position: relative;
	height: 100%;
}

.gridContainer {
	min-height: auto;
}

.linkGridHome {
	text-decoration: none;
	color: #000000;
}

.imgGrid {
	border-radius: 5px;
	height: 80%;
}

.nameCard {
	text-transform: capitalize;
}

.noStock {
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	align-items: center;
	min-height: calc(100vh - 184px);
}

.boxAddressShippingContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.titleFirstRow{
    font-size: 0.9rem;
    font-family: 'Quicksand', sans-serif;
}

.boxIconAndAddressContainer{
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
}

.boxIconLocation{
    padding-right: 10px;
}

.boxAddressContainer{
    width: 100%;
}

.addressNameAndSelectContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.boxNameAddress{
    padding-right: 10px;
}

.boxTextDefault{
    padding-top: 3px;
}

.textName{
    text-transform: capitalize;
}

.textDefault{
    color: #FFA500;
    text-transform: uppercase;
    font-family: 'Quicksand', sans-serif;
}

.textAddress{
    text-transform: capitalize;
}

.boxBtnContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 56px;
    margin-top: 40px;
}

.btnAddressShipping{
    font-family: 'Quicksand', sans-serif
}

.transportContainer{
    padding-bottom: 70px;
}

.titleSelectTransport{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px;
}

.boxTextField{
    display: flex;
    flex-direction: column;
    

}




.timerContainer {
  position: relative;
}
.timerCart {
	position: absolute;
	top: 12px;
	right: 45px;
}

@media screen and (max-width: 600px) {
	.timerCart {
		right: 40px;
	}
}
.titleSizeGuide{
    font-family: 'Quicksand', sans-serif;
    font-size: 1rem;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10px;
}

.containerTitleSizeGuide{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 400px;
}

.iconSizeGuide:hover{
    margin-left: 0px;
}

.btnBackSizeGuide{
    border-radius: 100%;
    padding: 5px;
    border:hidden;
    background-color:white;
}
.btnBackSizeGuide:hover{
    background-color: #E7EBF0;
}

.titleColumn{
    font-weight: bold;
}

.textSize{
    font-weight: bold;
    text-transform: uppercase;
}


.sizeCardContainer {
	padding-top: 20px;
}

.typographySizesAndColors {
	text-transform: capitalize;
	padding-bottom: 15px;
}

.sizeBtnColor:focus {
	border: 3px solid black;
}

.sizeBtnColor {
	border-radius: 100%;
	padding: 15px 15px 15px 15px;
	margin-right: 10px;
	margin-left: 10px;
}

.sizeTextColor {
	text-transform: capitalize;
	padding-left: 5px;
	padding-right: 5px;
}

.sizeTextSelectColor {
	padding-top: 20px;
	padding-bottom: 20px;
	font-family: "Quicksand", sans-serif;
}

.sizeText {
	border: 2px solid #e7ebf0;
	background-color: #e7ebf0;
	width: 40px;
	height: 30px;
	border-radius: 10%;
	font-family: "Quicksand", sans-serif;
	padding-top: 3%;
	text-align: center;
}

.sizeTextStock {
	font-family: "Quicksand", sans-serif;
	height: 30px;
	padding-top: 4%;
	padding-right: 60px;
	padding-left: 5px;
}

.sizeTextTotal {
	font-family: "Quicksand", sans-serif;
	padding-bottom: 10px;
}

.itemcountContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #f5f6f7;
	border-radius: 5px;
	max-width: 120px;
	min-width: 105px;
	margin: 0 auto;
}

.config-items {
	text-decoration: none;
	text-transform: uppercase;
}

.swal2-styled.swal2-confirm.swalBtnOkCkout {
	color: #fafafa;
	background-color: #292929;
}

.swal2-styled.swal2-confirm.swalBtnOkCkout:focus {
	box-shadow: none;
}

.swal2-styled.swal2-cancel.swalBtnCancelCkout {
	color: #292929;
	background-color: #fafafa;
}

.swal2-title.swalTitleCkout {
	font-size: 1.3rem;
}

.swal2-container.swalTextSize {
	font-size: 14px;
}

.ordersSpinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

