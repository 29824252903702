.login-logo {
	position: relative;
	right: 50%;
	left: 50%;
	margin: 5% auto 10%;
	width: 120px;
	height: auto;
	transform: translateX(-50%);
}

.create-user-link {
	color: #fafafa;
	text-decoration: none;
}
