.titleSizeGuide{
    font-family: 'Quicksand', sans-serif;
    font-size: 1rem;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10px;
}

.containerTitleSizeGuide{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 400px;
}

.iconSizeGuide:hover{
    margin-left: 0px;
}

.btnBackSizeGuide{
    border-radius: 100%;
    padding: 5px;
    border:hidden;
    background-color:white;
}
.btnBackSizeGuide:hover{
    background-color: #E7EBF0;
}

.titleColumn{
    font-weight: bold;
}

.textSize{
    font-weight: bold;
    text-transform: uppercase;
}

