.sliderColors {
	display: flex;
	flex-wrap: wrap;
}

.sliderColors::-webkit-scrollbar {
	display: none;
}

.paletteColor {
	border: 1px solid rgb(197, 195, 195);
	border-radius: 100%;
	margin: 8px;
}

.typographyItemDetail {
	text-transform: capitalize;
	padding-bottom: 15px;
}

.sizeTextColor {
	padding-top: 10px;
	text-align: left;
	font-family: "Quicksand", sans-serif;
}

.typographyItemDetailDescription {
	text-align: left;
	padding-top: 10px;
	font-family: "Quicksand", sans-serif;
	text-transform: capitalize;
}

.linkBtnSizesAndColor {
	text-decoration: none;
	width: 100%;
}

.sizeGuide {
	padding: 40px 0px 20px 0px;
	color: #000000;
}

.linksizeGuide {
	text-decoration: none;
}

.shippingMethods {
	padding: 20px 0px 40px 0px;
}

.iconItemDetail {
	padding-right: 20px;
	color: #000000;
}

.cardActionsButton {
	display: flex;
	flex-direction: column;
}

.productAdded {
	font-size: 1.1rem;
}

.productAddedIcon {
	font-size: 0.9rem;
}

.itemDetailContainer {
	position: relative;
	height: 100%;
	display: flex;
	justify-content: center;
	max-width: 1538px;
	margin: 0 auto 2rem;
}

.cardContainer {
	position: relative;
	margin-top: 3rem;
	height: 100%;
	min-width: 100vw;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.detailContent {
	margin: 0 1.5rem;
}

.boxBtnsAndImg {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.btnBackItemDetail {
	border: hidden;
	position: absolute;
	border-radius: 100%;
	left: 0.8rem;
	padding: 1rem;
	background-color: transparent;
}

.btnBackItemDetail:hover {
	background-color: #e7ebf0;
  cursor: pointer;
}

.btnBackItemDetailMobile {
	display: none;
}

.boxButtonsBack {
	display: flex;
	flex-direction: column;
}

.btnChangeImgBack {
	border: hidden;
	position: relative;
	padding: 10px;
	background-color: transparent;
}

.btnChangeImgBack:hover {
	background-color: #e7ebf0;
	border-radius: 20%;
  cursor: pointer;
}

.imgCard {
	width: 100%;
	max-width: 500px;
}

.boxButtonFoward {
	display: flex;
	flex-direction: column;
}

.btnChangeImgFoward {
	border: hidden;
	position: relative;
	padding: 10px;
	background-color: transparent;
}

.btnChangeImgFoward:hover {
	background-color: #e7ebf0;
	border-radius: 20%;
  cursor: pointer;
}

.boxBtnHandleChangeImg {
	text-align: center;
	bottom: 0;
	padding-top: 10px;
}

.btnHandleChangeImg {
	border-radius: 100%;
	width: 20px;
	height: 20px;
	margin: 5px;
	border: transparent;
	background-color: #ced4da;
	padding-top: 10px;
}

@media screen and (max-width: 1332px) {
	.detailContainer {
		width: 45%;
	}
}

@media screen and (max-width: 824px) {
	.cardContainer {
		margin-top: 0;
		flex-wrap: wrap;
	}

	.detailContainer {
		width: 100%;
	}

	.btnBackItemDetail {
		display: none;
	}

	.btnBackItemDetailMobile {
		display: inline;
		position: absolute;
		left: 0.5rem;
		top: 1rem;
		border: hidden;
		border-radius: 100%;
		background-color: transparent;
	}

	.btnBackItemDetailMobile:hover {
		background-color: #e7ebf0;
    cursor: pointer;
	}

	.btnChangeImgBack {
		padding: 5px;
	}

	.btnChangeImgFoward {
		padding: 5px;
	}
}
