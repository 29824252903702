.itemcountContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #f5f6f7;
	border-radius: 5px;
	max-width: 120px;
	min-width: 105px;
	margin: 0 auto;
}
