.swal2-styled.swal2-confirm.swalBtnOkCkout {
	color: #fafafa;
	background-color: #292929;
}

.swal2-styled.swal2-confirm.swalBtnOkCkout:focus {
	box-shadow: none;
}

.swal2-styled.swal2-cancel.swalBtnCancelCkout {
	color: #292929;
	background-color: #fafafa;
}

.swal2-title.swalTitleCkout {
	font-size: 1.3rem;
}

.swal2-container.swalTextSize {
	font-size: 14px;
}
