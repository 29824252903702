.slider {
	overflow-x: scroll;
	overflow-y: hidden;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
	display: none;
}

.filterButtons {
	display: flex;
}
