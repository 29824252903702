.homeContainer {
	display: flex;
	flex-direction: column;
	align-content: center;
	padding-right: 20px;
	padding-left: 20px;
	margin-bottom: 2rem;
}

.boxCategories {
	text-align: center;
	padding-top: 25px;
	margin-top: 5px;
}

.imgCategoriesContainer {
	display: flex;
	justify-content: center;
}

.slick-next {
	display: none !important;
}

.slick-prev {
	display: none !important;
}

.imgCategories {
	border-radius: 100%;
	width: 70px;
	height: 70px;
}

.nameCategory {
	font-family: "Quicksand", sans-serif;
	text-transform: lowercase;
}

.nameCategory::first-letter {
	text-transform: uppercase;
}

.imgBannerContainer {
	padding-top: 10px;
	max-height: 400px;
}

.imgBanner {
	max-height: 400px;
	width: 100%;
	border-radius: 5px;
	object-fit: cover;
}

.imgGridContainer {
	position: relative;
	margin-top: 45px;
	height: 100%;
}

.gridContainer {
	min-height: auto;
}

.linkGridHome {
	text-decoration: none;
	color: #000000;
}

.imgGrid {
	border-radius: 5px;
	height: 80%;
}

.nameCard {
	text-transform: capitalize;
}