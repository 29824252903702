.boxAddressShippingContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.titleFirstRow{
    font-size: 0.9rem;
    font-family: 'Quicksand', sans-serif;
}

.boxIconAndAddressContainer{
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
}

.boxIconLocation{
    padding-right: 10px;
}

.boxAddressContainer{
    width: 100%;
}

.addressNameAndSelectContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.boxNameAddress{
    padding-right: 10px;
}

.boxTextDefault{
    padding-top: 3px;
}

.textName{
    text-transform: capitalize;
}

.textDefault{
    color: #FFA500;
    text-transform: uppercase;
    font-family: 'Quicksand', sans-serif;
}

.textAddress{
    text-transform: capitalize;
}

.boxBtnContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 56px;
    margin-top: 40px;
}

.btnAddressShipping{
    font-family: 'Quicksand', sans-serif
}

.transportContainer{
    padding-bottom: 70px;
}

.titleSelectTransport{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px;
}

.boxTextField{
    display: flex;
    flex-direction: column;
    

}



