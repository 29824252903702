.sizeCardContainer {
	padding-top: 20px;
}

.typographySizesAndColors {
	text-transform: capitalize;
	padding-bottom: 15px;
}

.sizeBtnColor:focus {
	border: 3px solid black;
}

.sizeBtnColor {
	border-radius: 100%;
	padding: 15px 15px 15px 15px;
	margin-right: 10px;
	margin-left: 10px;
}

.sizeTextColor {
	text-transform: capitalize;
	padding-left: 5px;
	padding-right: 5px;
}

.sizeTextSelectColor {
	padding-top: 20px;
	padding-bottom: 20px;
	font-family: "Quicksand", sans-serif;
}

.sizeText {
	border: 2px solid #e7ebf0;
	background-color: #e7ebf0;
	width: 40px;
	height: 30px;
	border-radius: 10%;
	font-family: "Quicksand", sans-serif;
	padding-top: 3%;
	text-align: center;
}

.sizeTextStock {
	font-family: "Quicksand", sans-serif;
	height: 30px;
	padding-top: 4%;
	padding-right: 60px;
	padding-left: 5px;
}

.sizeTextTotal {
	font-family: "Quicksand", sans-serif;
	padding-bottom: 10px;
}
