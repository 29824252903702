.imgBannerContainer {
	padding-top: 20px;
}

.imgBanner {
	width: 100%;
	height: auto;
	border-radius: 5px;
}

.imgCategoryContainer {
	position: relative;
	height: 100%;
}

.gridContainer {
	min-height: auto;
}

.linkGridHome {
	text-decoration: none;
	color: #000000;
}

.imgGrid {
	border-radius: 5px;
	height: 80%;
}

.nameCard {
	text-transform: capitalize;
}

.noStock {
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	align-items: center;
	min-height: calc(100vh - 184px);
}
