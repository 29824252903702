@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Quicksand:wght@300;700&display=swap");

#btnForm {
	margin-top: 10px;
	margin-bottom: 20px;
	padding: 5px;
}

.errorMessage {
	color: red;
	font-size: 13px;
	margin-left: 5px;
}

.main {
	background-color: #0f0f0f;
	min-height: 100vh;
}

.form-logo {
	width: 120px;
	height: auto;
	padding-bottom: 30px;
	padding-top: 20px;
}

.css-1f0jaut-MuiButtonBase-root-MuiCheckbox-root {
	color: #fafafa;
}

input {
	border-color: #fafafa;
}

.css-an51-MuiButtonBase-root-MuiButton-root {
	font-size: 1rem;
	font-weight: 700;
	border-radius: 0;
	text-transform: none;
}

.css-1exth5i-MuiButtonBase-root-MuiButton-root {
	text-transform: none;
	margin-top: 0.7rem;
}

.swal2-styled.swal2-confirm.swalBtnOk {
	color: white;
}

.swal2-styled.swal2-confirm.swalBtnOk {
	color: #fafafa;
	box-shadow: 0 0 0 3px rgb(100 150 200 / 50%);
}

.swal2-title.swalTitle {
	font-size: 1.3rem;
}
