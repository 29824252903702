.sliderCategoriesContainer {
  display: flex;
  margin-top: 2rem;
  align-items: center;
  width: 100%;
}
.sliderCategories {
  width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}

.categoryContainer {
  margin: 0 2rem;
}

.sliderCategoryImgContainer {
  display: flex;
	justify-content: center;
}

.sliderCategoryImg {
	border-radius: 100%;
	width: 70px;
	height: 70px;
}

.sliderCategoryName {
  font-family: "Quicksand", sans-serif;
	text-transform: lowercase;
  text-align: center;
}

.sliderCategoryName::first-letter {
	text-transform: uppercase;
}