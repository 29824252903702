.menu-links {
	font-family: Quicksand, sans-serif;
	color: #292929;
	text-decoration: none;
	text-transform: uppercase;
}

.user-account {
	color: #292929;
	width: 100%;
	text-align: start;
	text-decoration: none;
}

.timer {
	position: absolute;
	top: 20px;
	right: 45px;
}

@media screen and (max-width: 1200px) {
	.timer {
		top: 19px;
		right: 45px;
	}
}

@media screen and (max-width: 600px) {
	.timer {
		top: 15px;
		right: 45px;
	}
}

.navbarLogo img {
	width: auto;
	height: 100%;
}
