.timerContainer {
  position: relative;
}
.timerCart {
	position: absolute;
	top: 12px;
	right: 45px;
}

@media screen and (max-width: 600px) {
	.timerCart {
		right: 40px;
	}
}