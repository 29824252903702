.gridContainer {
  position: relative;
	min-height: auto;
	padding: 0 0 2rem;
}

.linkGridHome {
	text-decoration: none;
	color: #000000;
}

.productImgCard {
	display: block;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	object-fit: cover;
	border-radius: 5px;
	height: 80%;
	width: 100%;
}

.sliderContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.sliderItemDetail {
  width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}

.colorsArrow {
  opacity: 30%;
}

.priceContainer {
	position: relative;
	display: flex;
}

.priceContainer__priceDiscount {
	position: absolute;
	bottom: 32px;
	right: 4px;
	background-color: #42ab49;
	padding: 3px 4px;
	border-radius: 5px;
}
